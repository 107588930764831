<template>
  <wrapper>

    <modal :title="`Delete ${organisation.name}`" v-if="modal.isVisible('deleteOrg')" @close="modal.hide('deleteOrg'); deletionString = null;" :show-footer="false">
      <p class="text-danger lead">This school will be permanently deleted. <br/> This action is irreversible.</p>
      <div class="input-group width-100">
        <input class="form-control width-100" name="name" :placeholder="`Enter '${organisation.name}' to confirm`" v-model="deletionString" type="text" autocomplete="off">
      </div>
      <div class="modal-footer">
        <slot name="footer"></slot>
        <loading-button type="button" class="btn btn-warning" data-dismiss="modal" @click="modal.hide('deleteOrg'); deletionString = null;">Cancel</loading-button>
        <loading-button type="button" class="btn btn-primary btn-danger" data-dismiss="modal" @click="deleteOrg" :disabled="deletionString !== organisation.name">Delete Permanently</loading-button>
      </div>
    </modal>
    <div class="challenge-organisation-page" v-if="organisation">
      <div class="row">
        <div class="col-md-6">
          <div class="box box-info mb-6">
            <div class="box-body">

              <table class="table table-striped" v-if="!isEditingSchool">
                <tbody>
                  <tr>
                    <th>Contact</th>
                    <td>{{ organisation.contact_name }} ({{ organisation.contact_job_role }})<br /><a
                        :href="'mailto:' + organisation.email">{{ organisation.email }}</a></td>
                  </tr>
                  <tr>
                    <th>Challenge Code</th>
                    <td>{{ organisation.code }}</td>
                  </tr>
                  <tr>
                    <th>Governance</th>
                    <td>{{ governanceDescription }}</td>
                  </tr>
                  <tr>
                    <th>Region</th>
                    <td>{{ organisation.region }}</td>
                  </tr>
                  <tr>
                    <th>PURL</th>
                    <td>{{ organisation.joining_url }}</td>
                  </tr>
                </tbody>
              </table>

              <div v-if="isEditingSchool">
                <form action="#" class="form-horizontal school-form" @submit.prevent="validateEditSchoolForm">

                  <div class="form-group" :class="{ 'has-error': errors.first('name') }">
                    <label for="name" class="col-sm-2 control-label">School Name</label>
                    <div class="col-sm-10 input-group">
                      <input type="text" class="form-control" name="name" id="name" autocomplete="off"
                        v-model="organisationEditable.name" v-validate="'required'">
                    </div>
                    <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.has('name')">
                      {{ errors.first('name') }}
                    </div>
                  </div>

                  <div class="form-group" :class="{ 'has-error': errors.first('contact_name') }">
                    <label for="contact_name" class="col-sm-2 control-label">Contact Name</label>
                    <div class="col-sm-10 input-group">
                      <input type="text" class="form-control" name="contact_name" id="contact_name" autocomplete="off"
                        v-model="organisationEditable.contact_name" v-validate="'required'">
                    </div>
                    <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.has('contact_name')">
                      {{ errors.first('contact_name') }}
                    </div>
                  </div>

                  <div class="form-group" :class="{ 'has-error': errors.first('contact_job_role') }">
                    <label for="contact_job_role" class="col-sm-2 control-label">Contact Job Role</label>
                    <div class="col-sm-10 input-group">
                      <input type="text" class="form-control" name="contact_job_role" id="contact_job_role"
                        autocomplete="off" v-model="organisationEditable.contact_job_role" v-validate="'required'">
                    </div>
                    <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.has('contact_job_role')">
                      {{ errors.first('contact_job_role') }}
                    </div>
                  </div>

                  <div class="form-group" :class="{ 'has-error': errors.first('email') }">
                    <label for="email" class="col-sm-2 control-label">Contact Email</label>
                    <div class="col-sm-10 input-group">
                      <input type="text" class="form-control" name="email" id="email" autocomplete="off"
                        v-model="organisationEditable.email" v-validate="{ required: true, email: true }">
                    </div>
                    <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.has('email')">
                      {{ errors.first('email') }}
                    </div>
                  </div>

                  <div class="form-group" :class="{ 'has-error': errors.first('country') }">
                    <label for="country" class="col-sm-2 control-label">Country</label>
                    <div class="col-sm-10 input-group">
                      <select class="form-control" name="country" id="country" autocomplete="off"
                        v-model="organisationEditable.country" v-validate="'required'" data-vv-as="Country">
                        <option v-for="country in countries" :value="country['id']" :key="country['id']">
                          {{ country['name'] }}</option>
                      </select>
                    </div>
                    <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.has('country')">
                      {{ errors.first('country') }}
                    </div>
                  </div>

                  <div class="form-group" :class="{ 'has-error': errors.first('region') }">
                    <label for="region" class="col-sm-2 control-label">Region</label>
                    <div class="col-sm-10 input-group">
                      <select v-if="regions.length > 0" class="form-control" name="region" id="region"
                        autocomplete="off" v-model="organisationEditable.region" v-validate="'required'"
                        data-vv-as="Region">
                        <optgroup :label="group['group_name'] != '' ? group['group_name'] : 'Chose a region'"
                          v-for="(group, key) in regions" :key="key">
                          <option v-for="(region, k) in group['values']" :value="region" :key="k">{{ region }}</option>
                        </optgroup>
                      </select>
                      <input v-else type="text" class="form-control" name="region" id="region" autocomplete="off"
                        v-model="organisationEditable.region" data-vv-as="region">
                    </div>
                    <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.has('region')">
                      {{ errors.first('region') }}
                    </div>
                  </div>

                  <div class="form-group" :class="{ 'has-error': errors.first('school_type') }">
                    <label for="school_type" class="col-sm-2 control-label">School Type</label>
                    <div class="col-sm-10 input-group">
                      <select class="form-control" name="school_type" id="school_type" autocomplete="off"
                        v-model="organisationEditable.school_type" v-validate="'required'" data-vv-as="School Type">
                        <option v-for="(v, k) in schoolTypes" :value="k" :key="k">{{ v }}</option>
                      </select>
                    </div>
                    <div class="col-sm-10 col-sm-offset-2 help-block is-danger" v-show="errors.has('school_type')">
                      {{ errors.first('school_type') }}
                    </div>
                  </div>

                  <div class="form-group" :class="{ 'has-error': errors.first('school_governed_by_name') }"
                    v-if="['la', 'mat'].includes(organisationEditable.school_type)">
                    <label for="school_governed_by_name" class="col-sm-2 control-label"
                      v-text="organisationEditable.school_type == 'la' ? 'Which Local Authority?' : 'Which MAT?'"></label>
                    <div class="col-sm-10 input-group">
                      <input type="text" class="form-control" name="school_governed_by_name"
                        id="school_governed_by_name" autocomplete="off"
                        v-model="organisationEditable.school_governed_by_name" v-validate="'required'">
                    </div>
                    <div class="col-sm-10 col-sm-offset-2 help-block is-danger"
                      v-show="errors.has('school_governed_by_name')">
                      {{ errors.first('school_governed_by_name') }}
                    </div>
                  </div>

                  <div class="box-footer">
                    <loading-button type="submit" :isLoading="isSavingSchool">Save</loading-button>
                  </div>
                </form>
              </div>

            </div>
          </div>
          <!-- <div v-if="user.readers">
            <h3>Reader Profiles</h3>
            <div v-if="user.readers.length === 0" class="alert alert-warning">The user has not yet created any reader profiles</div>
            <box-with-icons v-for="reader in user.readers" :key="reader.id" @click="$router.push(`${user.id}/readers/${reader.id}`)" class="reader-profile__wrap">
              <div class="reader-profile">
                <img alt="avatar" :src="'/img/fonetti_avatars/' + reader.avatar + '.png'" class="avatar" />
                <div>
                  <a @click="$router.push(`${user.id}/readers/${reader.id}`)">{{ reader.name }} ({{ reader.username }})</a><br>
                  Age: {{ reader.age }}
                </div>
              </div>
            </box-with-icons>
          </div>

        -->
          <div class="box box-info mb-6">
            <div class="box-body">
              <p class="text-center">Asset generation status: {{ organisation.assets_generation_status }}</p>
              <div class="flex gap-3 flex-wrap">
                <a v-for="(link, key) in organisation.assets" :key="key" class="btn btn-default btn-sm" :href="link"
                  target="_blank">{{ key }}</a>
              </div>
              <div class="text-center mt-5">
                <loading-button @click="forceRegenAssets" class="btn-success" :isLoading="isRegenerating">Force
                  Regenerate</loading-button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="flex justify-content-end column-gap-3">
            <!-- <button class="btn btn-primary btn-flex" @click="startResetPassword">Reset Password</button> -->

            <button type="button" class="btn btn-primary" @click="startEditingSchool">Edit School Info</button>
            <confirmation-button :show-modal="showConfirmationModal['reset-password']"
              @close="showConfirmationModal['reset-password'] = false" @click="handleResetPassword"
              :hide-modal-footer="true">
              <template #button>Reset password</template>
              <template #modal>
                <div v-if="null === newPassword">
                  <p>The school will no longer be able to use their current password to login.</p>
                  <p>The new password will be displayed here for you to copy & paste.</p>
                  <input type="checkbox" id="send-password-email" v-model="sendPasswordEmail" /> <label
                    for="send-password-email">also send the new password in an email to the school contact</label>
                  <div class="flex justify-content-end">
                    <button class="btn btn-success" @click="handleResetPasswordContinue"
                      :disabled="isResetting">Continue</button>
                  </div>
                </div>
                <div v-else>
                  <p class="text-center"><b>New Password</b></p>
                  <input type="text" :value="newPassword" class="form-control font-monospace" readonly />
                  <div class="flex justify-content-end mt-4">
                    <button class="btn btn-primary"
                      @click="showConfirmationModal['reset-password'] = false">Close</button>
                  </div>
                </div>
              </template>
            </confirmation-button>
            <button class="btn btn-danger" @click="modal.show('deleteOrg')">Delete school</button>
          </div>
        </div>
      </div>
    </div>
  </wrapper>
</template>

<script>
import Wrapper from '@/components/Wrapper';
import ConfirmationButton from '@/components/utils/ConfirmationButton';
import LoadingButton from '@/components/utils/LoadingButton';
import pickObj from 'lodash/pick';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/classes/Modal';

export default {
  name: 'challengeOrganisation',
  components: {
    Wrapper,
    ConfirmationButton,
    LoadingButton,
    ModalJS, // eslint-disable-line
    modal: ModalJS,
  },
  data() {
    return {
      organisation: null,

      isEditingSchool: false,
      isSavingSchool: false,
      organisationEditable: null,

      showConfirmationModal: {
        'reset-password': false,
      },

      isResetting: false,
      sendPasswordEmail: false,
      newPassword: null,

      isRegenerating: false,

      countries: [],
      schoolTypes: {
        independent: 'Independent',
        mat: 'Multi-Academy Trust',
        la: 'Local Authority',
        other: 'Other',
      },

      deletionString: null,
      modal: new Modal({
        deleteOrg: false,
      }),
    };
  },
  async created() {
    const payload = {
      challengeSlug: this.$route.params.challengeId,
      orgId: this.$route.params.orgId,
    };
    this.$store.dispatch('challenges/getChallengeOrganisation', payload).then((organisation) => {
      this.organisation = organisation;
    }).catch((err) => {
      this.$apiResponse.renderErrorMessage(err);
    });
  },
  computed: {
    governanceDescription() {
      let body = 'Other';
      switch (this.organisation.school_type) {
        case 'la': body = 'Local Authority'; break;
        case 'mat': body = 'Multi-Academy Trust'; break;
        case 'independant': body = 'Independant'; break;
        default: body = 'Other';
      }

      const bodyName = this.organisation.school_governed_by_name ?? '';

      return body + (bodyName.length > 0 ? ' - ' + bodyName : ''); // eslint-disable-line
    },

    regions() {
      const selectedCountry = this.countries.find((country) => country.id === this.organisationEditable.country);
      return selectedCountry && selectedCountry.regions ? selectedCountry.regions.groups : [];
    },
  },
  methods: {
    handleResetPassword() {
      const action = 'reset-password';
      this.showConfirmationModal[action] = true;
    },
    async handleResetPasswordContinue() {
      this.isResetting = true;
      const payload = {
        challengeSlug: this.$route.params.challengeId,
        orgId: this.$route.params.orgId,
        data: {
          send_email: this.sendPasswordEmail,
        },
      };
      this.$store.dispatch('challenges/resetPassword', payload).then((newPassword) => {
        this.newPassword = newPassword;
      }).catch((err) => {
        this.$apiResponse.renderErrorMessage(err);
      }).finally(() => {
        this.isResetting = false;
      });
    },
    async getCountries() {
      try {
        const response = await this.$store.dispatch('app/fetchCountries');
        this.countries = response.data.countries;
      } catch (error) {
        this.$apiResponse.renderErrorMessage(error);
      }
    },
    startEditingSchool() {
      this.organisationEditable = pickObj(this.organisation, ['name', 'contact_name', 'contact_job_role', 'email', 'country', 'region', 'school_type', 'school_governed_by_name']);
      this.isEditingSchool = true;
    },
    async validateEditSchoolForm() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          await this.submitEditSchool();
        }
      });
    },
    async submitEditSchool() {
      this.isSavingSchool = true;

      // If not LA or MAT, clear out the governed by name
      if (!(['la', 'mat'].includes(this.organisationEditable.school_type))) {
        this.organisationEditable.school_governed_by_name = null;
      }

      const payload = {
        challengeSlug: this.$route.params.challengeId,
        orgId: this.$route.params.orgId,
        data: this.organisationEditable,
      };
      this.$store.dispatch('challenges/updateChallengeOrganisation', payload).then((organisation) => {
        this.organisation = organisation;
        this.isEditingSchool = false;
      }).catch((err) => {
        this.$apiResponse.renderErrorMessage(err);
      }).finally(() => {
        this.isSavingSchool = false;
      });
    },
    async forceRegenAssets() {
      this.isRegenerating = true;
      const payload = {
        challengeSlug: this.$route.params.challengeId,
        orgId: this.$route.params.orgId,
      };
      this.$store.dispatch('challenges/challengeOrganisationRegenAssets', payload).then((organisation) => {
        this.organisation = organisation;
      }).catch((err) => {
        this.$apiResponse.renderErrorMessage(err);
      }).finally(() => {
        this.isRegenerating = false;
      });
    },
    deleteOrg() {
      this.$store.dispatch('app/setGlobalLoading', true);
      this.$store.dispatch('organisations/deleteOrganisation', {
        challengeSlug: this.$route.params.challengeId,
        orgId: this.$route.params.orgId,
      }).then(() => {
        this.$router.push('/reports/challenges?am=organisation_deleted');
      }).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      }).finally(() => {
        this.$store.dispatch('app/setGlobalLoading', false);
      });
    },
  },
  watch: {
    organisation() {
      this.$route.meta.title = this.organisation.name;
      this.$route.meta.description = this.organisation.code;
    },
  },
};
</script>
