import _ from 'lodash';

class APIResponse {
  constructor(Vue, options) {
    this.Vue = Vue;
    this.errorMsg = null;
    this.successMsg = null;
    this.$vm = null;
    this.router = options.router;

    this.router.afterEach((to) => {
      this.cleanUp();
      if ('am' in to.query) {
        this.renderSuccessMessageFromQuery(to.query.am);
      }
    });
  }

  init(vm) {
    this.$vm = vm;
  }

  renderErrorMessage(error, message = null) {
    this.cleanUp();
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      this.router.replace('/logout');
    }
    if (error.error_type === 'generic') {
      message = 'Sorry something went wrong, please try again.';
    }
    this.errorMsg = APIResponse.pickErrorMessage(error, message);
    this.$vm.errorMsg = this.errorMsg;
    return this.errorMsg;
  }

  renderSuccessMessage(response, message = null) {
    this.cleanUp();

    this.successMsg = APIResponse.pickSuccessMessage(response, message);
    this.$vm.successMsg = this.successMsg;
    return this.successMsg;
  }

  renderSuccessMessageFromQuery(message) {
    this.cleanUp();

    switch (message) {
      case 'organisation_deleted':
        this.successMsg = 'The organisation was successfully deleted';
        break;
      default:
        this.successMsg = 'The operation was successful';
        break;
    }

    this.$vm.successMsg = this.successMsg;
    return this.successMsg;
  }

  renderDisappearingSuccessMessage(response, message, timeout = 3000) {
    this.renderSuccessMessage(response, message);
    setTimeout(() => {
      this.nullifySuccessMsg();
    }, timeout);
  }

  static pickSuccessMessage(response, message = null) {
    if (!_.isEmpty(message)) {
      return message;
    }

    if (!_.isEmpty(response.data.message)) {
      return response.data.message;
    }

    return 'The action was successful';
  }

  static pickErrorMessage(error, message) {
    if (!_.isEmpty(message)) {
      return message;
    }
    if (!_.isEmpty(error.response)) {
      if (error.response.data.error_type === 'validation') {
        let errors = '<ul>';
        _.map(error.response.data.errors, (err) => {
          errors += `<li>${err}</li>`;
        });
        errors += '</ul>';
        return errors;
      }

      return error.response.data.error;
    }

    if (!_.isEmpty(error.errors)) {
      if (error.error_type === 'validation') {
        let errors = '<ul>';
        _.map(error.errors, (err) => {
          errors += `<li>${err}</li>`;
        });
        errors += '</ul>';
        return errors;
      }
    }

    return 'An error occurred';
  }

  nullifyErrorMsg() {
    this.errorMsg = null;
    this.$vm.errorMsg = null;
    window.APIResponseEventBus.errorMsg = null;
  }

  nullifySuccessMsg() {
    this.errorMsg = null;
    this.$vm.errorMsg = null;
    window.APIResponseEventBus.successMsg = null;
  }

  cleanUp() {
    this.nullifyErrorMsg();
    this.nullifySuccessMsg();
  }
}

export default APIResponse;
