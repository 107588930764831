import api from '@/api';

export default ({
  namespaced: true,
  actions: {
    deleteOrganisation(context, payload) {
      const { challengeSlug, orgId } = payload;
      return api.delete(`admin/challenges/${challengeSlug}/organisations/${orgId}`);
    },
  },
});
