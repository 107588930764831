var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wrapper",
    [
      _vm.modal.isVisible("deleteOrg")
        ? _c(
            "modal",
            {
              attrs: {
                title: "Delete " + _vm.organisation.name,
                "show-footer": false
              },
              on: {
                close: function($event) {
                  _vm.modal.hide("deleteOrg")
                  _vm.deletionString = null
                }
              }
            },
            [
              _c("p", { staticClass: "text-danger lead" }, [
                _vm._v("This school will be permanently deleted. "),
                _c("br"),
                _vm._v(" This action is irreversible.")
              ]),
              _c("div", { staticClass: "input-group width-100" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.deletionString,
                      expression: "deletionString"
                    }
                  ],
                  staticClass: "form-control width-100",
                  attrs: {
                    name: "name",
                    placeholder:
                      "Enter '" + _vm.organisation.name + "' to confirm",
                    type: "text",
                    autocomplete: "off"
                  },
                  domProps: { value: _vm.deletionString },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.deletionString = $event.target.value
                    }
                  }
                })
              ]),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _vm._t("footer"),
                  _c(
                    "loading-button",
                    {
                      staticClass: "btn btn-warning",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: {
                        click: function($event) {
                          _vm.modal.hide("deleteOrg")
                          _vm.deletionString = null
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "loading-button",
                    {
                      staticClass: "btn btn-primary btn-danger",
                      attrs: {
                        type: "button",
                        "data-dismiss": "modal",
                        disabled: _vm.deletionString !== _vm.organisation.name
                      },
                      on: { click: _vm.deleteOrg }
                    },
                    [_vm._v("Delete Permanently")]
                  )
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _vm.organisation
        ? _c("div", { staticClass: "challenge-organisation-page" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "box box-info mb-6" }, [
                  _c("div", { staticClass: "box-body" }, [
                    !_vm.isEditingSchool
                      ? _c("table", { staticClass: "table table-striped" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("Contact")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.organisation.contact_name) +
                                    " (" +
                                    _vm._s(_vm.organisation.contact_job_role) +
                                    ")"
                                ),
                                _c("br"),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "mailto:" + _vm.organisation.email
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.organisation.email))]
                                )
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Challenge Code")]),
                              _c("td", [_vm._v(_vm._s(_vm.organisation.code))])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Governance")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.governanceDescription))
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Region")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.organisation.region))
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("PURL")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.organisation.joining_url))
                              ])
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm.isEditingSchool
                      ? _c("div", [
                          _c(
                            "form",
                            {
                              staticClass: "form-horizontal school-form",
                              attrs: { action: "#" },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.validateEditSchoolForm($event)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "form-group",
                                  class: {
                                    "has-error": _vm.errors.first("name")
                                  }
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-sm-2 control-label",
                                      attrs: { for: "name" }
                                    },
                                    [_vm._v("School Name")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-10 input-group" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.organisationEditable.name,
                                            expression:
                                              "organisationEditable.name"
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "name",
                                          id: "name",
                                          autocomplete: "off"
                                        },
                                        domProps: {
                                          value: _vm.organisationEditable.name
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.organisationEditable,
                                              "name",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("name"),
                                          expression: "errors.has('name')"
                                        }
                                      ],
                                      staticClass:
                                        "col-sm-10 col-sm-offset-2 help-block is-danger"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.errors.first("name")) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group",
                                  class: {
                                    "has-error": _vm.errors.first(
                                      "contact_name"
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-sm-2 control-label",
                                      attrs: { for: "contact_name" }
                                    },
                                    [_vm._v("Contact Name")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-10 input-group" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.organisationEditable
                                                .contact_name,
                                            expression:
                                              "organisationEditable.contact_name"
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "contact_name",
                                          id: "contact_name",
                                          autocomplete: "off"
                                        },
                                        domProps: {
                                          value:
                                            _vm.organisationEditable
                                              .contact_name
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.organisationEditable,
                                              "contact_name",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("contact_name"),
                                          expression:
                                            "errors.has('contact_name')"
                                        }
                                      ],
                                      staticClass:
                                        "col-sm-10 col-sm-offset-2 help-block is-danger"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.errors.first("contact_name")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group",
                                  class: {
                                    "has-error": _vm.errors.first(
                                      "contact_job_role"
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-sm-2 control-label",
                                      attrs: { for: "contact_job_role" }
                                    },
                                    [_vm._v("Contact Job Role")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-10 input-group" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.organisationEditable
                                                .contact_job_role,
                                            expression:
                                              "organisationEditable.contact_job_role"
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "contact_job_role",
                                          id: "contact_job_role",
                                          autocomplete: "off"
                                        },
                                        domProps: {
                                          value:
                                            _vm.organisationEditable
                                              .contact_job_role
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.organisationEditable,
                                              "contact_job_role",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has(
                                            "contact_job_role"
                                          ),
                                          expression:
                                            "errors.has('contact_job_role')"
                                        }
                                      ],
                                      staticClass:
                                        "col-sm-10 col-sm-offset-2 help-block is-danger"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.errors.first("contact_job_role")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group",
                                  class: {
                                    "has-error": _vm.errors.first("email")
                                  }
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-sm-2 control-label",
                                      attrs: { for: "email" }
                                    },
                                    [_vm._v("Contact Email")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-10 input-group" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.organisationEditable.email,
                                            expression:
                                              "organisationEditable.email"
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              required: true,
                                              email: true
                                            },
                                            expression:
                                              "{ required: true, email: true }"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "email",
                                          id: "email",
                                          autocomplete: "off"
                                        },
                                        domProps: {
                                          value: _vm.organisationEditable.email
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.organisationEditable,
                                              "email",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("email"),
                                          expression: "errors.has('email')"
                                        }
                                      ],
                                      staticClass:
                                        "col-sm-10 col-sm-offset-2 help-block is-danger"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.errors.first("email")) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group",
                                  class: {
                                    "has-error": _vm.errors.first("country")
                                  }
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-sm-2 control-label",
                                      attrs: { for: "country" }
                                    },
                                    [_vm._v("Country")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-10 input-group" },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.organisationEditable
                                                  .country,
                                              expression:
                                                "organisationEditable.country"
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            name: "country",
                                            id: "country",
                                            autocomplete: "off",
                                            "data-vv-as": "Country"
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                _vm.organisationEditable,
                                                "country",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            }
                                          }
                                        },
                                        _vm._l(_vm.countries, function(
                                          country
                                        ) {
                                          return _c(
                                            "option",
                                            {
                                              key: country["id"],
                                              domProps: { value: country["id"] }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(country["name"])
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("country"),
                                          expression: "errors.has('country')"
                                        }
                                      ],
                                      staticClass:
                                        "col-sm-10 col-sm-offset-2 help-block is-danger"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.errors.first("country")) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group",
                                  class: {
                                    "has-error": _vm.errors.first("region")
                                  }
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-sm-2 control-label",
                                      attrs: { for: "region" }
                                    },
                                    [_vm._v("Region")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-10 input-group" },
                                    [
                                      _vm.regions.length > 0
                                        ? _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.organisationEditable
                                                      .region,
                                                  expression:
                                                    "organisationEditable.region"
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                name: "region",
                                                id: "region",
                                                autocomplete: "off",
                                                "data-vv-as": "Region"
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.organisationEditable,
                                                    "region",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(_vm.regions, function(
                                              group,
                                              key
                                            ) {
                                              return _c(
                                                "optgroup",
                                                {
                                                  key: key,
                                                  attrs: {
                                                    label:
                                                      group["group_name"] != ""
                                                        ? group["group_name"]
                                                        : "Chose a region"
                                                  }
                                                },
                                                _vm._l(
                                                  group["values"],
                                                  function(region, k) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: k,
                                                        domProps: {
                                                          value: region
                                                        }
                                                      },
                                                      [_vm._v(_vm._s(region))]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            }),
                                            0
                                          )
                                        : _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.organisationEditable
                                                    .region,
                                                expression:
                                                  "organisationEditable.region"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              name: "region",
                                              id: "region",
                                              autocomplete: "off",
                                              "data-vv-as": "region"
                                            },
                                            domProps: {
                                              value:
                                                _vm.organisationEditable.region
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.organisationEditable,
                                                  "region",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("region"),
                                          expression: "errors.has('region')"
                                        }
                                      ],
                                      staticClass:
                                        "col-sm-10 col-sm-offset-2 help-block is-danger"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.errors.first("region")) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group",
                                  class: {
                                    "has-error": _vm.errors.first("school_type")
                                  }
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-sm-2 control-label",
                                      attrs: { for: "school_type" }
                                    },
                                    [_vm._v("School Type")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-10 input-group" },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.organisationEditable
                                                  .school_type,
                                              expression:
                                                "organisationEditable.school_type"
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            name: "school_type",
                                            id: "school_type",
                                            autocomplete: "off",
                                            "data-vv-as": "School Type"
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                _vm.organisationEditable,
                                                "school_type",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            }
                                          }
                                        },
                                        _vm._l(_vm.schoolTypes, function(v, k) {
                                          return _c(
                                            "option",
                                            { key: k, domProps: { value: k } },
                                            [_vm._v(_vm._s(v))]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("school_type"),
                                          expression:
                                            "errors.has('school_type')"
                                        }
                                      ],
                                      staticClass:
                                        "col-sm-10 col-sm-offset-2 help-block is-danger"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.errors.first("school_type")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              ["la", "mat"].includes(
                                _vm.organisationEditable.school_type
                              )
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "form-group",
                                      class: {
                                        "has-error": _vm.errors.first(
                                          "school_governed_by_name"
                                        )
                                      }
                                    },
                                    [
                                      _c("label", {
                                        staticClass: "col-sm-2 control-label",
                                        attrs: {
                                          for: "school_governed_by_name"
                                        },
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.organisationEditable
                                              .school_type == "la"
                                              ? "Which Local Authority?"
                                              : "Which MAT?"
                                          )
                                        }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-sm-10 input-group"
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.organisationEditable
                                                    .school_governed_by_name,
                                                expression:
                                                  "organisationEditable.school_governed_by_name"
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              name: "school_governed_by_name",
                                              id: "school_governed_by_name",
                                              autocomplete: "off"
                                            },
                                            domProps: {
                                              value:
                                                _vm.organisationEditable
                                                  .school_governed_by_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.organisationEditable,
                                                  "school_governed_by_name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "school_governed_by_name"
                                              ),
                                              expression:
                                                "errors.has('school_governed_by_name')"
                                            }
                                          ],
                                          staticClass:
                                            "col-sm-10 col-sm-offset-2 help-block is-danger"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.errors.first(
                                                  "school_governed_by_name"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "box-footer" },
                                [
                                  _c(
                                    "loading-button",
                                    {
                                      attrs: {
                                        type: "submit",
                                        isLoading: _vm.isSavingSchool
                                      }
                                    },
                                    [_vm._v("Save")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "box box-info mb-6" }, [
                  _c("div", { staticClass: "box-body" }, [
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v(
                        "Asset generation status: " +
                          _vm._s(_vm.organisation.assets_generation_status)
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex gap-3 flex-wrap" },
                      _vm._l(_vm.organisation.assets, function(link, key) {
                        return _c(
                          "a",
                          {
                            key: key,
                            staticClass: "btn btn-default btn-sm",
                            attrs: { href: link, target: "_blank" }
                          },
                          [_vm._v(_vm._s(key))]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "text-center mt-5" },
                      [
                        _c(
                          "loading-button",
                          {
                            staticClass: "btn-success",
                            attrs: { isLoading: _vm.isRegenerating },
                            on: { click: _vm.forceRegenAssets }
                          },
                          [_vm._v("Force Regenerate")]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "col-md-6" }, [
                _c(
                  "div",
                  { staticClass: "flex justify-content-end column-gap-3" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.startEditingSchool }
                      },
                      [_vm._v("Edit School Info")]
                    ),
                    _c("confirmation-button", {
                      attrs: {
                        "show-modal":
                          _vm.showConfirmationModal["reset-password"],
                        "hide-modal-footer": true
                      },
                      on: {
                        close: function($event) {
                          _vm.showConfirmationModal["reset-password"] = false
                        },
                        click: _vm.handleResetPassword
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button",
                            fn: function() {
                              return [_vm._v("Reset password")]
                            },
                            proxy: true
                          },
                          {
                            key: "modal",
                            fn: function() {
                              return [
                                null === _vm.newPassword
                                  ? _c("div", [
                                      _c("p", [
                                        _vm._v(
                                          "The school will no longer be able to use their current password to login."
                                        )
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "The new password will be displayed here for you to copy & paste."
                                        )
                                      ]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.sendPasswordEmail,
                                            expression: "sendPasswordEmail"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "send-password-email"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.sendPasswordEmail
                                          )
                                            ? _vm._i(
                                                _vm.sendPasswordEmail,
                                                null
                                              ) > -1
                                            : _vm.sendPasswordEmail
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = _vm.sendPasswordEmail,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.sendPasswordEmail = $$a.concat(
                                                    [$$v]
                                                  ))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.sendPasswordEmail = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.sendPasswordEmail = $$c
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          attrs: { for: "send-password-email" }
                                        },
                                        [
                                          _vm._v(
                                            "also send the new password in an email to the school contact"
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex justify-content-end"
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-success",
                                              attrs: {
                                                disabled: _vm.isResetting
                                              },
                                              on: {
                                                click:
                                                  _vm.handleResetPasswordContinue
                                              }
                                            },
                                            [_vm._v("Continue")]
                                          )
                                        ]
                                      )
                                    ])
                                  : _c("div", [
                                      _c("p", { staticClass: "text-center" }, [
                                        _c("b", [_vm._v("New Password")])
                                      ]),
                                      _c("input", {
                                        staticClass:
                                          "form-control font-monospace",
                                        attrs: { type: "text", readonly: "" },
                                        domProps: { value: _vm.newPassword }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex justify-content-end mt-4"
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-primary",
                                              on: {
                                                click: function($event) {
                                                  _vm.showConfirmationModal[
                                                    "reset-password"
                                                  ] = false
                                                }
                                              }
                                            },
                                            [_vm._v("Close")]
                                          )
                                        ]
                                      )
                                    ])
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2150824090
                      )
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        on: {
                          click: function($event) {
                            return _vm.modal.show("deleteOrg")
                          }
                        }
                      },
                      [_vm._v("Delete school")]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }