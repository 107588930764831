import Vue from 'vue';
import Vuex from 'vuex';
import authStore from './store/modules/auth';
import usersStore from './store/modules/users';
import reportsStore from './store/modules/reports';
import challengesStore from './store/modules/challenges';
import adminsStore from './store/modules/admins';
import schoolsStore from './store/modules/schools';
import organisationsStore from './store/modules/organisations';
import appStore from './store/modules/app';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: authStore,
    users: usersStore,
    reports: reportsStore,
    challenges: challengesStore,
    admins: adminsStore,
    schools: schoolsStore,
    organisations: organisationsStore,
    app: appStore,
  },
  state: {
    user: null,
    token: null,
    isLoading: false,
    accountType: null,
    currentSchool: null,
    currentUser: null,
    adminLTE: false,
    pageHeadingTitle: null,
    recordingsTableState: null,
    usersTableState: null,
  },
  mutations: {
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_CURRENT_SCHOOL(state, currentSchool) {
      state.currentSchool = currentSchool;
    },
    SET_CURRENT_USER(state, currentUser) {
      state.currentUser = currentUser;
    },
    SET_ADMIN_LTE(state, value) {
      state.adminLTE = value;
    },
    SET_PAGE_HEADING_TITLE(state, str) {
      state.pageHeadingTitle = str;
    },
    SET_RECORDINGS_TABLE_STATE(state, tblState) {
      state.recordingsTableState = tblState;
    },
    SET_USERS_TABLE_STATE(state, tblState) {
      state.usersTableState = tblState;
    },
    SET_CHALLENGES_STATE(state, challenges) {
      state.challenges = challenges;
    },
  },
  actions: {
    setLoading({ commit }, isLoading) {
      commit('setLoading', isLoading);
    },
  },
  getters: {
    getCurrentSchool: (state) => state.currentSchool,
    getCurrentUser: (state) => state.currentUser,
    isLoggedIn: (state) => !!state.token,
    isLoading: (state) => state.isLoading,
    adminLTEIsLoaded: (state) => state.adminLTE,
    getDefaultDateRange: (state) => {
      if (state.defaultDateRange === null) {
        return JSON.parse(window.localStorage.getItem('default_date_range'));
      }

      return state.defaultDateRange;
    },
    getUsersTableState: (state) => state.usersTableState,
  },
});
