<template>
  <div class="action-messages" v-if="errorMsg || successMsg">
    <div class="alert alert-danger" role="alert" v-show="errorMsg">
      <i class="fa fa-exclamation-triangle"></i> <span v-html="errorMsg"></span>
    </div>
    <div class="alert alert-success" role="alert" v-show="successMsg">
      <i class="fa fa-check"></i> <span v-html="successMsg"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'action-messages',
  computed: {
    errorMsg() {
      return window.APIResponseEventBus.errorMsg;
    },
    successMsg() {
      return window.APIResponseEventBus.successMsg;
    },
  },
};
</script>

<style>
.alert ul {
  margin-bottom: 0;
}
</style>
